// Copyright (C) 2020 Farsight Software Ltd.. All rights reserved.
import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import PageInfo from "../components/pageinfo";

const I18NSection = () => (
    <section className="coming-soon__intro flex-container">

        <div className="flex-container flex-container--columns container--large highlight-links">
            <h1>
                Coming Soon:
            </h1>
            <p>

                Coverage of
                the <a href="https://www.ecma-international.org/ecma-402/">
                    ECMAScript Internationalization API Specification
                </a>
            </p>
            <p>
                More about what's coming soon <Link to="/coming-soon">here</Link>.
            </p>
        </div>
    
    </section> 
);

const I18NPage = () => (
    <Layout>
        <PageInfo title="Internationalization" />
        <I18NSection />
    </Layout>
);

export default I18NPage;
